import React from "react";
import bug from "../img/bug.png";
import eil from "../img/eil.png";

const openGraphImageTemplate = ({ pageContext }) => (
  <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '1200px',
      height: '630px',

      background: `linear-gradient(180deg, rgba(245,128,37,0.1183176100629) 0%, rgba(255,255,255,1) 70%)`,
      overflow: 'hidden'
  }}>
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        height: '100%',
    }}>
        <div style={{
            fontWeight: 'bold',
            fontSize: 56,
            color: 'black',
            height: '33%'
        }}>
            Gerrymandering Project
        </div>
        <div
            style={{
                fontWeight: 'bold',
                fontSize: 48,
                color: '#f58025',
                height: '50%'
            }}
        >{  pageContext && 
            pageContext.cardTitle ? 
            pageContext.cardTitle 
            : 'Fixing Bugs in Democracy'}
        </div>
    </div>
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        minWidth: '268px'
    }}>
        <img
            src={bug}
            style={{
                width: '100px',
                height: '176px',
                marginTop: '25px'
            }}
            alt={`Gerrymandering Project`}
        >
        </img>
        <img
            src={eil}
            style={{
                width: '268px',
                height: '100px',
                marginBottom: '50px'
            }}
            alt={`Electoral Innovation Lab`}
        >
        </img>
    </div>
  </div>
);

export default openGraphImageTemplate;